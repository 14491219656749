import React from 'react'

import Layout from '../components/Layout'
import Logo from '../components/Logo'
import Mailer from '../components/Mailer'

import './index.css'

const IndexPage = ({ data }) => (
  <Layout>
    <div className="container">
      <Logo />
      <h2>Coming soon to the Lehigh Valley and surrounding areas.</h2>
      <Mailer />
    </div>
  </Layout>
)

export default IndexPage
