import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './Logo.css'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image = () => (
  <StaticQuery
    // query={graphql`
    //   query {
    //     placeholderImage: file(relativePath: { eq: "poptop_logo.png" }) {
    //       childImageSharp {
    //         fluid(maxWidth: 300) {
    //           ...GatsbyImageSharpFluid
    //         }
    //       }
    //     }
    //   }
    // `}
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "poptop_logo.png" }) {
          childImageSharp {
            resize {
              src
            }
          }
        }
      }
    `}
    render={data => (
      <img
        className="logo"
        alt="poptop canning logo"
        src={data.placeholderImage.childImageSharp.resize.src}
      />
    )}
  />
)
export default Image
