import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import './Mailer.css'

const url =
  '//poptopcanning.us19.list-manage.com/subscribe/post?u=f46b48bcc0b16b2a9dfc6df99&amp;id=5ddc0f70bc'

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email, name
  const submit = () =>
    email &&
    name &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value,
    })

  return (
    <div>
      {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
      {status === 'error' && (
        <div
          style={{ color: 'red' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          style={{ color: 'green' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="inputs">
        <input
          style={{ fontSize: '2em' }}
          ref={node => (name = node)}
          type="text"
          placeholder="Your name"
        />
        <input
          style={{ fontSize: '2em' }}
          ref={node => (email = node)}
          type="email"
          placeholder="Your email"
        />
      </div>
      <button onClick={submit}>Submit</button>
    </div>
  )
}

const Mailer = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => {
      if (status === 'success')
        return (
          <div style={{ color: 'green' }}>
            Thanks for subscribing! We shall email you of any updates in the
            near future.
          </div>
        )

      return (
        <div>
          <CustomForm onValidated={formData => subscribe(formData)} />
          {status === 'sending' && (
            <div style={{ color: 'blue' }}>sending...</div>
          )}
          {status === 'error' && (
            <div
              style={{ color: 'red', margin: 20 }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      )
    }}
  />
)

export default Mailer
